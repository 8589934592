/************** d3forum genelic *************/
/* xoopsCode */
#post_reference dd div.xoopsCode,
#post_preview dd div.xoopsCode,
div.d3f_body div.xoopsCode {
    border: 1px #aaaaaa solid;
    width: auto;
    overflow: auto;
    padding: 0;
    margin: 10px 0;
}
div.xoopsCode pre {
    white-space: pre;
}
/* xoopsQuote */
div.d3f_body blockquote {
    margin: 0 0 5px;
    padding: 5px;
}
div.d3f_body div.xoopsQuote {
    border: 1px #aaaaaa solid;
    margin: 0;
    padding: 0;
}
#post_reference dd blockquote,
#post_preview dd blockquote {
    margin: 0;
    padding: 5px;
}

/************** d3forum CSS *************/

/* em.d3f_attn */
em.d3f_attn {
    font-style: normal;
    border-bottom: 1px #333 dotted;
    cursor: help;
}

/* d3f_breadcrumbs */
div.d3f_breadcrumbs {
    font-size: 95%;
    padding: 0 0 3px;
    border-bottom: 1px #aaa solid;
}

/* h1 */
.d3f_title {
    font-size: 130%;
    margin: 3px 0 15px;
}
.d3f_title * {
    vertical-align: middle;
}

/* avatar */
.d3f_avatar {
    position: relative;
    z-index: 110;
    border: 4px #ffffff solid;
    margin: -3px -5px 0 0;
    float: right;
}

/* each post */
.d3f_head {
    padding: 4px 8px;
    line-height: 100%;
    color: #ffffff;
    background-color: #778899;
}
.d3f_head h2 {
    position: relative;
    z-index: 101;
    line-height: 120%;
    font-size: 120%;
    font-weight: bold;
    margin: 0;
    padding: 0;
}
.d3f_head h2 a {
    text-decoration: none;
}
.d3f_head h2 a:link {
    color: #ffffff;
}
.d3f_head h2 a:active {
    color: #ed5656;
}
.d3f_head h2 a:visited {
    color: #ffffff;
}
.d3f_head h2 a:hover {
    color: #990000;
}
.d3f_head h2.invisible {
    background-color: #f00;
}
.d3f_head h2.yetapproval {
    background-color: #0ff;
}
.d3f_head h2 img {
    vertical-align: middle;
}
.d3f_msgnum {
    /* color: #ffffff; */
    font-size: 95%;
    font-weight: normal;
}
.d3f_info {
    position: relative;
    margin: 2px 0 0;
    padding: 3px 8px;
    color: #000000;
    background-color: #eeeeee;
}

.d3f_head_tree_depth {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 3px 0;
    background-color: #d0d0d0;
}
dl.d3f_head_tree_depth dt,
dl.d3f_head_tree_depth dd {
    width: 1px;
    visibility: hidden;
    float: left;
}
dl.d3f_head_tree_depth dt {
    position: absolute;
}
.d3f_info_val {
    /* just only for indicator... */
    z-index: 101;
    position: relative;
}
.d3f_info_sub {
    padding: 2px 8px 3px;
    background-color: #ffffff;
}
.d3f_body {
    width: auto;
    clear: right;
    line-height: 150%;
    margin: 0 -2px;
    padding: 15px 8px;
    border-top: 1px #aaaaaa dotted;
}
.d3f_info_ctrl {
    line-height: 100%;
    text-align: right;
    clear: both;
    margin: 2px -2px 2px;
    padding: 2px 10px;
    background-color: #eeeeee;
    border-top: 1px #aaaaaa dotted;
    border-bottom: 1px #aaaaaa solid;
}
.d3f_info_ctrl form input,
.d3f_info_ctrl img {
    vertical-align: middle;
}

/* h2 */
h2.d3f_head {
    font-size: 120%;
    padding: 3px 8px;
}
h2.d3f_tree {
    margin-bottom: 4px;
}

/* main_listposts.html */
.d3f_wrap {
    width: 100%;
    clear: both;
    border-top: 1px #aaaaaa solid;
    border-right: 1px #aaaaaa solid;
    border-left: 1px #aaaaaa solid;
    border-bottom: 1px #aaaaaa solid;
    padding: 2px 2px 0;
    margin-bottom: 0.8em;
}

/* d3f_form */
div.d3f_form_base {
    clear: both;
}

form.d3f_form p input {
    vertical-align: middle;
}

/* d3f_ctrl */
.d3f_ctrl {
    width: 100%;
    border: 1px #aaaaaa solid;
    color: #000000;
    background-color: #f8f8f8;
    margin: 5px 0;
    padding: 2px;
    line-height: 150%;
    clear: both;
}
.d3f_ctrl li {
    margin-left: 25px;
}

div.d3f_order_ctrl {
    float: right;
    font-size: 0.9em;
    padding-bottom: 1em;
}

.boxNavi + div.d3f_order_ctrl {
    padding: 0;
}

div.d3f_order_ctrl a {
    margin-left: 10px;
}

/* h3 */
h3.d3f_head_h3 {
    font-size: 120%;
    margin: 5px 0 0;
    padding: 2px 8px;
    border-bottom: 1px #aaaaaa solid;
}

/* d3f_orderctrl */
p.d3f_viewctrl {
    margin: 0;
    padding: 3px;
    text-align: right;
}

/* d3f_topicinfo */
.d3f_topicinfo {
    text-align: left;
    margin: 0;
    padding: 2px;
}

/* main_viewpost.html */
/* eachbranch */
ul.d3f_eachbranch {
    margin: 0;
    padding: 0;
}
ul.d3f_eachbranch li {
    list-style: none outside;
    margin: 0;
    padding: 0;
}
ul.d3f_eachbranch li img {
    vertical-align: middle;
}

/* d3f_currenttopic */
.d3f_eachbranch li.d3f_eachbranchitem,
.d3f_eachbranch span.d3f_eachbranchitem {
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #eeeeee;
}
.d3f_eachbranch li.d3f_currenttopic {
    background-color: #ffffff;
}
.d3f_eachbranch li.d3f_currenttopic > span,
.d3f_eachbranch li.d3f_currenttopic > span a {
    color: #990000;
}
td span.d3f_currenttopic:after,
.d3f_eachbranch li.d3f_currenttopic > span:first-child:after {
    content: "\00ab";
    color: #ff0000;
    font-weight: bold;
}
.d3f_eachbranch li.d3f_currenttopic > span.d3f_thistopic {
    color: #ff0000;
}

/* main_listforums.html */
/* d3f_subcategories */
ul.d3f_subcategories li {
    list-style: none outside;
}

/* d3forum_main_listcategories.html */
/* infomations*/
dl.d3f_bbsinfo {
    font-size: 95%;
    margin: 0;
    float: left;
}
dl.d3f_bbsinfo dt,
dl.d3f_bbsinfo dd {
    display: inline;
    margin: 0;
}
dl.d3f_bbsinfo dd {
    margin: 0 5px 0 0;
}
dl.d3f_timeinfo {
    clear: both;
    font-size: 95%;
    margin: 0;
    padding: 0 0 10px;
    float: left;
}
dl.d3f_timeinfo dt,
dl.d3f_timeinfo dd {
    display: inline;
    margin: 0;
}
dl.d3f_timeinfo dd {
    margin: 0 5px 0 0;
}

ul.d3f_listforums li {
    list-style: none outside;
}

/* main_listtopics.html */
/* d3f_bbsviewctrl */
div.d3f_bbsviewctrl {
    line-height: 100%;
    float: left;
    padding-bottom: 3px;
}
div.d3f_bbsviewctrl form {
    margin: 0;
    padding: 0;
}
div.d3f_bbsviewctrl form *,
p.d3f_bbsviewctrl * {
    vertical-align: middle;
    margin: 0;
    padding: 0;
}

/* d3f_pagenav */
.d3f_pagenav {
    text-align: right;
    padding: 3px;
}

table.d3f_table {
    width: 100%;
    clear: both;
}
table.d3f_table thead th,
table.d3f_table td {
    text-align: center !important;
    padding: 3px;
    vertical-align: middle;
}
table.d3f_table td.d3f_topictitle,
table.d3f_table td.d3f_posters {
    text-align: left !important;
}
table.d3f_table td.d3f_mainicon {
    padding: 0;
}

table.d3f_table td img {
    vertical-align: middle;
    margin-right: 3px;
}

table.d3f_table td dl {
    text-align: left !important;
    margin: 0 -3px;
    padding: 0;
}
table.d3f_table td dt {
    margin: 0;
    padding: 3px 3px 5px;
}
table.d3f_table td dd {
    margin: 0;
    padding: 0 3px 5px;
}
table.d3f_table td dd.d3f_td_topicinfo {
    margin: 0;
    padding: 3px 3px 1px;
    border-top: 1px #777777 dashed;
    font-size: 95%;
}

/* d3f_iconexp */
div.d3f_iconexps ul.d3f_iconexp {
    width: 45%;
    float: left;
}

ul.d3f_iconexp {
    padding-bottom: 15px;
}

ul.d3f_iconexp li {
    list-style: none outside;
    padding: 3px 0;
}

ul.d3f_iconexp li img {
    vertical-align: middle;
    margin-right: 3px;
}

/* d3forum forms */
#post_reference dd,
#post_preview dd {
    margin: 0;
}

/* table.d3f_form_table */
table.d3f_form_table td,
table.d3f_form_table th {
    vertical-align: top !important;
    text-align: left;
}

/* d3f_submit */
p.d3f_submit {
    text-align: center;
    margin: 0;
    padding: 10px;
}

/*  Page Navigation */
div.boxNavi .itemBody {
    margin: 1px 2px 5px 2px;
    text-align: center;
    list-style-type: none;
    background-color: transparent;
}
div.boxNavi .itemBody li {
    display: inline;
    margin: 1px 2px 1px 2px;
    padding: 5px 0px 5px 0px;
    font-weight: bold;
    text-align: center;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    background-image: none;
}

div.boxNavi .itemBody li.txt span,
div.boxNavi .itemBody li.this span,
div.boxNavi .itemBody li a {
    margin: 0;
    padding: 5px 7px 5px 10px;
    text-decoration: none;
}
div.boxNavi .itemBody li.this span {
    background-color: #cccccc;
}

.d3f_post_tree {
    padding: 2px;
    border: dotted 1px #cccccc;
}

h2.head {
    width: 98%;
    margin: 0 auto;
    padding: 4px 4px 0;
}

.d3f_order_ctrl + h2.head {
    margin: 1em 0 0;
}

.d3f_wrap .itemInfo {
    padding: 0.5em 1em;
}

.d3f_wrap .itemFoot {
    font-size: 0.9em;
    margin-bottom: 4px;
    clear: both;
}

.d3f_wrap .itemFoot + .itemHead {
    border-top: solid 1px #aaa;
}

.d3f_wrap .itemFoot form input[type="submit"] {
    font-size: 0.9em;
    padding: 1px;
    vertical-align: inherit;
}

form.d3f_form #d3f_form_jump_box {
    text-align: center;
}
