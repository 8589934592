/* HTML TAG Re-definition */
body {
    background: #fff;
    font-family: Verdana, Arial, Helvetica, sans-serif, "ＭＳ Ｐゴシック", Osaka, "ヒラギノ角ゴ Pro W3";
    color: #666666;
    margin: 0px;
    padding: 0px 15px 15px;
    font-size: 80%;
    min-width: 880px;
    max-width: 1280px;
}
a {
    color: #6075aa;
}
a:hover {
    color: #ff9900;
}
h1,
h2,
h3 {
    color: #286367;
}
h4,
h5 {
    color: #6075aa;
}
ul {
    margin: 2px;
    padding: 2px;
    list-style: decimal inside;
    text-align: left;
}
li {
    margin-left: 2px;
    list-style: square inside;
}
table {
    width: 100%;
    margin: 0;
    padding: 0;
}
th {
    color: #6075aa;
    background-color: #e3e7f0;
    padding: 4px;
    vertical-align: middle;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #c7c7c7;
}
td {
    padding: 0;
    vertical-align: top;
    font-family: Verdana, Arial, Helvetica, sans-serif;
}
legend {
    padding: 3px;
    color: #990000;
    font-size: 120%;
    font-weight: bold;
}
form {
    margin: 0;
    padding: 0;
}
textarea {
    width: 400px;
}

.clearfix::after {
    content: "";
    display: block;
    clear: both;
}

/* header */
.header {
    width: 100%;
}
.header .headerLeft {
    vertical-align: middle;
    padding-left: 15px;
    float: left;
    height: 128px;
}
.header .headerRight {
    top: 0px;
    right: 15px;
    text-align: right;
    white-space: nowrap;
    float: right;
    height: 128px;
}
.header .titleJapanese {
    font-size: 40px;
    line-height: 120px;
    font-weight: bold;
}
.header .titleEnglish {
    font-size: 48px;
    line-height: 120px;
}
.header .titleLink,
.header .titleLink:hover,
.header .titleLink:visited {
    color: #666666;
    text-decoration: none;
}
.header .menuBar {
    border: 1px solid #b7d0d5;
    border-radius: 4px;
    box-shadow: 0px -5px 10px -5px #b7d0d5 inset;
    white-space: nowrap;
    vertical-align: middle;
}
.header .menuLink {
    margin: 8px 10px;
}
.header .menuLink a {
    color: #6075aa;
    font-weight: bold;
    text-decoration: none;
    background-image: url(./images/menu_indent.gif);
    background-repeat: no-repeat;
    background-position: left center;
    padding: 0px 10px 0px 16px;
}
.header .menuLink a:hover {
    color: #286367;
    background-image: url(./images/menu_indent_h.gif);
}

/* footer */
.footer {
    margin: 20px 0 30px;
    font-size: 87%;
    line-height: 150%;
    padding-top: 10px;
    border-top: 1px dotted #cccccc;
    text-align: right;
}
.footer a {
    padding-left: 10px;
}

/* main part */
.main {
    margin: 15px 5px 30px;
}
.mainContent {
    padding-top: 8px;
    padding-bottom: 15px;
}

/* Left Column */
.leftcolumn {
    float: left;
    width: calc(25% - 1px);
    border-right-width: 1px;
    border-right-style: dotted;
    border-right-color: #999999;
}
.leftcolumn .block {
    margin: 0 15px 20px 0;
}
.leftcolumn .block .blockTitle {
    margin: 0;
    padding-left: 24px;
    font-weight: bold;
    background-image: url(./images/title_indent.gif);
    background-repeat: no-repeat;
    color: #286367;
    font-size: 11pt;
    line-height: 28px;
    height: 24px;
}
.leftcolumn .block .blockContent {
    margin: 5px 0 10px 0;
    line-height: 120%;
}

/* Center Column */
.centercolumn {
    float: left;
    width: 75%;
    text-align: left;
    line-height: 150%;
}
.centercolumn > .block {
    margin: 0 10px 2em 15px;
}

.centerCcolumn {
    width: 100%;
    margin: 0 6px 20px;
}
.centerCcolumn .block {
    margin-bottom: 20px;
}
.centerCcolumn .block .blockTitle {
    margin: 0;
    padding: 0;
    height: 28px;
    font-weight: bold;
    color: #6075aa;
    text-indent: 15px;
    font-size: 11pt;
    line-height: 28px;
    border: 1px solid #b7d0d5;
    border-radius: 4px;
    box-shadow: 0px -5px 10px -5px #b7d0d5 inset;
    white-space: nowrap;
    vertical-align: middle;
}
.centerCcolumn .block .blockContent {
    margin: 10px 5px;
    line-height: 150%;
}

.centerLcolumn {
    float: left;
    width: 50%;
}
.centerLcolumn .block {
    margin: 0 10px 0 0;
}
.centerLcolumn .block .blockTitle {
    margin: 0;
    padding: 0;
    text-indent: 24px;
    font-size: 11pt;
    font-weight: bold;
    background-image: url(./images/title_indent2.gif);
    background-repeat: no-repeat;
    color: #365196;
}
.centerLcolumn .block .blockContent {
    margin: 10px 5px;
    line-height: 120%;
}

.centerRcolumn {
    float: right;
    width: 50%;
}
.centerRcolumn .block {
    margin: 0;
}
.centerRcolumn .block .blockTitle {
    margin: 0;
    padding: 0;
    text-indent: 24px;
    font-size: 11pt;
    font-weight: bold;
    background-image: url(./images/title_indent3.gif);
    background-repeat: no-repeat;
    color: #984068;
}
.centerRcolumn .block .blockContent {
    margin: 10px 5px;
    line-height: 120%;
}
.centerMainContent {
    padding: 3px 3px 10px 3px;
    line-height: 150%;
}

/* main menu */
.mainmenu li {
    list-style: none;
}

.mainmenu a {
    font-size: 95%;
    display: block;
    margin: 0;
    padding: 4px;
    border-bottom: 1px dotted #cdcdcd;
    text-decoration: none;
}

.menuTop {
    padding-left: 0.5em;
    border-bottom: 1px dotted #cdcdcd;
}

.menuMain {
    padding-left: 0.5em;
    border-bottom: 1px dotted #cdcdcd;
}

.menuSub {
    padding-left: 15px;
    border-bottom: 1px dotted #cdcdcd;
}

/* XOOPS Newbb & tabular work */
.outer {
    border: 0px solid #cc0000;
}
.head,
tr.head td {
    padding: 5px;
    font-weight: bold;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #c7c7c7;
    background-color: #f0f2f7;
}
.even {
    padding: 2px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #c7c7c7;
    background-color: #f8fafe;
}
.odd {
    padding: 2px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #c7c7c7;
    background-color: #ffffff;
}

tr.even td {
    padding: 2px;
    background-color: #f8fafe;
}
tr.odd td {
    padding: 2px;
    background-color: #ffffff;
}
.foot {
    padding: 5px;
    font-weight: bold;
}

.comTitle {
    font-weight: bold;
    margin-bottom: 2px;
}
.comText {
    padding: 2px;
}
.comUserStat {
    font-size: 90%;
    color: #2f5376;
    font-weight: bold;
    border: 1px solid silver;
    background-color: #ffffff;
    margin: 2px;
    padding: 2px;
}
.comUserStatCaption {
    font-weight: normal;
}
.comUserStatus {
    margin-left: 2px;
    margin-top: 10px;
    color: #2f5376;
    font-weight: bold;
    font-size: 10px;
}
.comUserRank {
    margin: 2px;
}
.comUserRankText {
    font-size: 90%;
    font-weight: bold;
}
.comUserRankImg {
    border: 0;
}
.comUserImg {
    margin: 2px;
}
.comDate {
    font-style: normal;
    font-size: 85%;
}
.comDateCaption {
    font-weight: bold;
    font-style: normal;
}

/* XOOPS News */
.itemHead {
    padding: 3px;
}
.itemInfo {
    text-align: right;
    padding: 3px;
    margin-bottom: 5px;
    border-bottom: 1px solid silver;
    font-style: italic;
}
.itemTitle {
    padding: 3px;
    font-size: 120%;
    font-weight: bold;
    color: #6075aa;
}
.itemTitle a {
    color: #286367;
    text-decoration: none;
}
.itemTitle a:hover {
    color: #ff9900;
}
.itemPoster {
    font-size: 90%;
    font-style: italic;
}
.itemPostDate {
    font-size: 90%;
    font-style: italic;
}
.itemStats {
    font-size: 80%;
}
.itemBody {
    padding: 3px;
}
.itemBody img {
    padding: 5px;
}
.itemTopicImage {
    float: left;
}
.itemText {
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 1.5em;
}
.itemText:first-letter {
    font-size: 133%;
    font-weight: bold;
}
.itemFoot {
    text-align: right;
    padding: 3px;
    margin-top: 5px;
    background-color: #efefef;
    border: 1px solid silver;
}
.itemAdminLink {
    font-size: 90%;
}
.itemPermaLink {
    font-size: 90%;
}

/* XOOPS Error Msg */
div.errorMsg {
    background-color: #ffcccc;
    text-align: center;
    border-top: 1px solid #ddddff;
    border-left: 1px solid #ddddff;
    border-right: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    font-weight: bold;
    padding: 10px;
}
div.confirmMsg {
    background-color: #ddffdf;
    color: #136c99;
    text-align: center;
    border-top: 1px solid #ddddff;
    border-left: 1px solid #ddddff;
    border-right: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    font-weight: bold;
    padding: 10px;
}
div.resultMsg {
    background-color: #cccccc;
    color: #333333;
    text-align: center;
    border-top: 1px solid silver;
    border-left: 1px solid silver;
    font-weight: bold;
    border-right: 1px solid #666666;
    border-bottom: 1px solid #666666;
    padding: 10px;
}

/* XOOPS Code &Quote */
div.xoopsCode {
    background: #ffffff url(./images/xoops_quote.jpg) no-repeat right top;
    border: 1px inset #000080;
    overflow: auto;
    max-height: 300px;
    max-width: 600px;
}
div.xoopsQuote {
    background: #ffffff url(./images/xoops_quote.jpg) no-repeat right top;
    border: 1px inset #000080;
    overflow: auto;
    max-height: 300px;
    max-width: 600px;
}

/* other */
table.report {
    background-color: #ff8;
}
